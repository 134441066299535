html body {
    width: 100%;
    margin: 0px;
}

#root {
    display: flex;
    justify-content: center;
    background-color: #efeef5;
}

.main-container {
    margin-top: 55px;
    /*padding: 10px;*/
}

@media only screen and (min-width: 500px) {
    .main-container {
        max-width: 1000px;
    }
}
img {
    max-width: 100%;
    height: auto;
}

.main-toolbar {
    display: flex;
    justify-content: right;
}

p {
    text-align: justify;
    word-break: break-word;
}
h1, p {
    font-family: 'Roboto Condensed', sans-serif;
}

.MuiAppBar-colorPrimary-16 {
    background-color: #334D88!important;
}

.paper {
    padding: 20px;
    margin-top: 40px;
}

.image-grid {
    text-align: center;
}

.text-grid{
    display: flex;
    align-items: center;
}

.divider {
    margin-top: 30px!important;
    margin-bottom: 20px!important;
}

.icon {
    background-color: #A90001!important;
}